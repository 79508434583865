import React from "react";
import axios from "axios";
import { useCode } from "./CodeContext";

function RunButton() {
  const { code, language, setOutput } = useCode();

  const handleRunClick = async () => {
    try {
      const response = await axios.post(
        "https://better-based-prysm.xyz:4535/execute",
        {
          code,
          language,
        }
      );

      if (
        typeof response.data === "string" ||
        typeof response.data === "number"
      ) {
        setOutput([response.data.toString()]);
      } else if (response.data.result) {
        setOutput([response.data.result.toString()]);
      } else {
        setOutput([JSON.stringify(response.data)]);
      }
    } catch (error) {
      console.error("Error running code:", error);
      setOutput(["Error running code: " + error.message]);
    }
  };

  return <button onClick={handleRunClick}>Run</button>;
}

export default RunButton;
